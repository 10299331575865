// Sidebar.js
import React, { useState } from "react";
import "./Sidebar.css";
import Clients from "./client";
import Notifications from "./notification";
import Prompts from "./prompts";
import ProfilePage from "./profile";
import PostsComponent from "./components/PostsComponent";

const Sidebar = () => {
  const [currentView, setCurrentView] = useState("clients");

  const renderView = () => {
    switch (currentView) {
      case "prompts":
        return <Prompts />;
      case "notifications":
        return <PostsComponent />;
      case "profile":
        return <ProfilePage/>;
      case "clients":
      default:
        return <Clients />;
    }
  };

  return (
    <div className="sidebar-dashboard-layout">
      <div className="side-bar">
        <ul className="nav-bar-list">
          <li className={`nav-item ${currentView === 'clients' ? 'active' : ''}`}>
            <button onClick={() => setCurrentView("clients")}>
              <img src={require("./img/people.svg").default} alt="clients"/>
              Clients
            </button>
          </li>
          <li className={`nav-item ${currentView === 'notifications' ? 'active' : ''}`}>
            <button onClick={() => setCurrentView("notifications")}>
              <img src={require("./img/bell.svg").default} alt="notifications"/>
              Activity
            </button>
          </li>
          <li className={`nav-item ${currentView === 'prompts' ? 'active' : ''}`}>
            <button onClick={() => setCurrentView("prompts")}>
              <img src={require("./img/send.svg").default} alt="prompt"/>
              Share
            </button>
          </li>
          <li className={`nav-item ${currentView === 'profile' ? 'active' : ''}`}>
            <button onClick={() => setCurrentView("profile")}>
              <img src={require("./img/profile.svg").default} alt="profile"/>
              Profile
            </button>
          </li>
        </ul>
      </div>
      <div className="main-view">{renderView()}</div>
    </div>
  );
};

export default Sidebar;
