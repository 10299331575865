import { useUser } from "../context/UserContext";
import Popup from "./Popup";
import "./certainpopup.css";
import { useState } from "react";

function AddGroup({ trigger, setTrigger }) {
  const [groupName, setGroupName] = useState("");
  const [users, setUsers] = useState("");
  const {userData} = useUser();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

  

  // Handle group creation
  const handleCreateGroup = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/create-group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ docId: userData.userId , groupName: groupName }), // Users as array
      });
      
      if (response.ok) { // Refresh groups
        alert(`Group ${groupName} created successfully.`);
        setTrigger(false); // Close the popup
      } else {
        throw new Error("Failed to create group");
      }
    } catch (error) {
      console.error("Error creating group:", error);
      alert("Error creating group.");
    }
  };

  return (
    <Popup trigger={trigger} setTrigger={setTrigger}>
      <div className="group-popup-header">
        <h3>Create a New Group</h3>
      </div>
      <h4>Group Name</h4>
      <div className="input-container">
        <input
          type="text"
          placeholder="Enter group name"
          className="full-width-input"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)} // Update group name state
        />
      </div>
      
      <button className="create-group-button" onClick={handleCreateGroup}>
        Create
      </button>
    </Popup>
  );
}

export default AddGroup;
