import React, { useEffect, useState } from "react";
import GroupList from "./GroupList";
import "../client.css";
import { useUser } from "../context/UserContext";

function Sidebar({ onSelectUser }) {
  const [groups, setGroups] = useState({});
  const [error, setError] = useState(null);
  const { userData } = useUser();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserData = setInterval(() => {
      if (userData) {
        clearInterval(checkUserData); // Stop checking once userData is available
        fetchData(); // Fetch user data
      }
    }, 500); // Check every 500ms

    return () => clearInterval(checkUserData); // Cleanup interval on unmount
  }, [userData]);

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/data/approved/${userData.userId}`);
      const result = await response.json();

      setGroups(result);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading user data...</div>; // Loading state
  }

  return (
    <div className="sidebar">
      {error ? (
        <div className="error">{error}</div>
      ) : (
        Object.keys(groups).map((groupName) => (
          <GroupList
            key={groupName}
            groupName={groupName}
            users={groups[groupName]}
            onSelectUser={onSelectUser}
          />
        ))
      )}
    </div>
  );
}

export default Sidebar;
