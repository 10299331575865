import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Chart = ({ title, dataPoints }) => {
  // Log data points for troubleshooting
 

  // Generate labels dynamically based on the `createdAt` field
  const labels = dataPoints.map((point) => point.createdAt || ""); // Fallback if `createdAt` is empty

  const data = {
    labels, // X-axis labels
    datasets: [
      {
        label: title,
        data: dataPoints.map((point) => Number(point.score)), // Convert score to number
        borderColor: "#4C3DFC", // Line color (purple)
        backgroundColor: "#4C3DFC",
        borderWidth: 5, // Line thickness
        pointRadius: 2.5, // Show data points as circles
        fill: false, // No area fill under the line
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        min: 1, // Set min value based on your range
        max: 6, // Set max value based on your range
        ticks: {
          stepSize: 1,
        },
        grid: {
          display: true,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide legend
      },
    },
  };

  return (
    <div style={{ height: "175px", width: "312px" }}>
      <h3>{title}</h3>
      <Line data={data} options={options} />
    </div>
  );
};

export default Chart;
