import React, { useState } from 'react';
import './TopBar.css'; // Add your custom CSS here
import logo from './img/icon.svg'; // Assume your Marble Connect logo image path
import InviteUserPopup from './components/ApproveUser';
import AddGroup from './components/AddGroup';
import ManageGroup from './components/ManagePopup';

const TopBar = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isManage, setIsManage] = useState(false); // State to control popup visibility
  const [isAdd, setIsAdd] = useState(false);

  const handlePlusClick = () => {
    setIsPopupOpen(true); // Open the popup when the plus icon is clicked
  };
  const handleManageGroups = () => {
    setIsManage(true); // Open the popup when the plus icon is clicked
  };
  const handleAddGroups = () => {
    setIsAdd(true); // Open the popup when the plus icon is clicked
  };

  return (
    <div className="top-bar">
      <div className="logo-section">
        <img src={logo} alt="Marble Connect" className="logo" />
        <h1 className="title">Marble Connect</h1>
      </div>
      <div className="search-section">
        {/* <input type="text" className="search-input" placeholder="Search" /> */}
        <button className="add-button" onClick={handlePlusClick}>
          <span className="text-style">Pending Approvals</span>
        </button>
        <button className="add-button" onClick={handleManageGroups}>
          <span className="text-style">Manage Groups</span>
        </button>
        <button className="add-button" onClick={handleAddGroups}>
          <span className="text-style">Add Groups</span>
        </button>
      </div>

      {/* Add the ApproveUserPopup here */}
      {isPopupOpen && <InviteUserPopup trigger={isPopupOpen} setTrigger={setIsPopupOpen} />}
      {isManage && <ManageGroup trigger={isManage} setTrigger={setIsManage} />}
      {isAdd && <AddGroup trigger={isAdd} setTrigger={setIsAdd} />}
    </div>
  );
};

export default TopBar;
