import React from "react";
import Sidebar from "./Sidebar"; // Sidebar includes both nav and view rendering
import "./dashboard.css";
import TopBar from "./TopBar";

export const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <TopBar /> 
      <Sidebar /> {/* Sidebar includes navigation and content */}
    </div>
  );
};

export default Dashboard;
