import React, { useState, useEffect } from 'react';
import "./styles.css";
import PostTile from './PostTile';
import { useUser } from '../context/UserContext';

const PostsComponent = () => {
  const [posts, setPosts] = useState([]);
  const { userData } = useUser();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

  // Function to fetch all posts from the backend
  const fetchPosts = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/getPosts/${userData.userId}`);
      const data = await response.json();

      if (data.posts.length > 0) {
        // Convert Firestore Timestamps to JavaScript Date objects
        const formattedPosts = data.posts.map(post => ({
          ...post,
          createdAt: post.createdAt ? new Date(post.createdAt["seconds"] * 1000 + post.createdAt["nanoseconds"] / 1000000) : new Date() // Convert Firestore Timestamp
        }));

        setPosts(formattedPosts);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  useEffect(() => {
    // Fetch posts when component mounts
    fetchPosts();
  }, []);

  // Group posts by date
  const groupPostsByDate = (posts) => {
    return posts.reduce((groups, post) => {
      let date;
  
      // Check if createdAt is a Firestore Timestamp or an object with seconds/nanoseconds
   
      if (post.createdAt) {
        // Firestore Timestamp: use toDate()
        date = post.createdAt;
      } else if (post.createdAt["seconds"] && post.createdAt) {
        // Firestore seconds/nanoseconds: convert to Date object
        date = new Date(post.createdAt["seconds"] * 1000 + post.createdAt["nanoseconds"] / 1000000);
      } else {
        // If no valid createdAt, use current date as fallback
        date = new Date();
      }

      // console.log(post, date);
  
      // Format the date using toDateString() for grouping
      const dateString = date.toDateString();
  
      // Initialize the group if it doesn't exist
      if (!groups[dateString]) {
        groups[dateString] = [];
      }
  
      // Add the post to the group
      groups[dateString].push(post);
   
  
      return groups;
    }, {});
  };
  

  const groupedPosts = groupPostsByDate(posts);

  return (
    <div className="posts-container">
      {Object.keys(groupedPosts).length > 0 ? (
        Object.keys(groupedPosts).map((date) => (
          <div key={date}>
            <h2>{date}</h2>
            {groupedPosts[date].map((post) => (
              <PostTile
                key={post.id}
                heading={post.type}
                text={post.text}
              />
            ))}
          </div>
        ))
      ) : (
        <h4>No posts available</h4>
      )}
    </div>
  );
};

export default PostsComponent;
