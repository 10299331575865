import React, { useState } from "react";
import { useUser } from "./context/UserContext"; // Import UserContext to access groups and saved prompts/notifications
import "./prompts.css";
import { Timestamp } from "firebase/firestore";


export const Prompts = () => {
  const { userData } = useUser(); // Access user data from UserContext
  const [searchTerm, setSearchTerm] = useState(""); // State for search term
  const [selectedGroup, setSelectedGroup] = useState(""); // State for selected group
  const [textInput, setTextInput] = useState(""); // State for the textarea (prompt or notification)
  const [isFocused, setIsFocused] = useState(false); // State to track input focus
  const [activeButton, setActiveButton] = useState("prompt"); // State to track active button

  // Assuming userData.Groups is an array of group names
  const groups = userData?.Groups || [];
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

  // Assuming userData.SavedPrompt and userData.SavedNotification are arrays
  const savedPrompts = userData?.SavedPrompt || [];
  const savedNotifications = userData?.SavedNotification || [];

  // Filtered groups based on the search term
  const filteredGroups = groups.filter((group) =>
    group.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle the dropdown visibility
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    // Check if the click is outside the dropdown
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsFocused(false);
    }
  };

  // Determine the label and saved data based on active button
  const isPromptActive = activeButton === "prompt";
  const labelText = isPromptActive ? "Prompt:" : "Notification:";
  const savedItems = isPromptActive ? savedPrompts : savedNotifications;

  // Handle submit based on active button
  const handleSubmit = async () => {
    const clientId = selectedGroup; // Use the selected group (clientId)

    if (!clientId) {
      alert("Please select a group.");
      return;
    }

    const postData = {
      group: selectedGroup,
      text: textInput,
      createdAt: Timestamp.now(),
    };

    try {
      const endpoint = isPromptActive ? 'api/prompt' : 'api/notification'; // Dynamically choose endpoint
      const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientId: userData.userId,
          data: postData, // Either the prompt or notification data
        }),
      });

      const result = await response.json();

      if (result) {
        alert(`${isPromptActive ? 'Prompt' : 'Notification'} created successfully!`);
        setTextInput(""); // Clear the input
      } else {
        alert(`Failed to create ${isPromptActive ? 'prompt' : 'notification'}.`);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="container">
      {/* Upper Section */}
      <div className="upper-section">
        <h4>To:</h4>
        <form onBlur={handleBlur}>
          {/* Searchable dropdown input */}
          <input
            className="text-boxes"
            id="search-bar"
            type="text"
            value={searchTerm} // Make the searchTerm editable
            placeholder="Search or select group"
            onFocus={handleFocus} // Show dropdown on focus
            onChange={(e) => {
              setSearchTerm(e.target.value); // Update search term dynamically
              setSelectedGroup(""); // Clear selected group if search term is updated
            }} 
          />
          {/* Dropdown for groups */}
          {isFocused && filteredGroups.length > 0 && (
            <ul className="dropdown">
              {filteredGroups.map((group, index) => (
                <li
                  key={index}
                  onMouseDown={() => {
                    setSelectedGroup(group); // Set the selected group
                    setSearchTerm(group); // Set search term to selected group
                    setIsFocused(false); // Hide dropdown after selection
                  }}
                  className="dropdown-item"
                >
                  {group}
                </li>
              ))}
            </ul>
          )}
        </form>
      </div>

      {/* Buttons to toggle between New Prompt and New Notification */}
      <div className="prompt-container">
        <button
          className={`prompt-button ${activeButton === "prompt" ? "active" : ""}`}
          onClick={() => setActiveButton("prompt")} // Activate prompt
        >
          New Prompt
        </button>
        <button
          className={`notification-button ${activeButton === "notification" ? "active" : ""}`}
          onClick={() => setActiveButton("notification")} // Activate notification
        >
          New Notification
        </button>
      </div>

      {/* Middle Section */}
      <div className="mid-section">
        <h4>{labelText}</h4>
        <form style={{ paddingTop: "10px" }}>
          <textarea
            className="text-boxes"
            id="text-bar"
            type="text"
            value={textInput} // Controlled input for the textarea
            onChange={(e) => setTextInput(e.target.value)} // Update the text input
          ></textarea>
        </form>
      </div>

      {/* Bottom Section */}
      <div className="bottom-section">
        <h4>{isPromptActive ? "Saved Prompts:" : "Saved Notifications:"}</h4>
        <div className="button-section">
          {/* Dynamically create buttons for saved prompts or notifications */}
          {savedItems.length > 0 ? (
            savedItems.map((item, index) => (
              <button
                key={index}
                className="buttons"
                onClick={() => setTextInput(item)} // Fill the textarea with the saved item when clicked
              >
                {item.substring(0, 20)}...
              </button>
            ))
          ) : (
            <button className="buttons">No saved {isPromptActive ? "prompts" : "notifications"}</button>
          )}
        </div>
      </div>

      {/* Send Text Button */}
      <button id="send-prompt" onClick={handleSubmit}>
        Send {isPromptActive ? "prompt" : "notification"}
      </button>
    </div>
  );
};

export default Prompts;
