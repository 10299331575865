// App.js
import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useAuth } from "./context/AuthContext"; // Import the AuthContext hook
import TopBar from "./TopBar";
import Dashboard from "./dashboard";
import Login from "./Login";
import Profile from "./profile";
import "./App.css";

function App() {
  const { currentUser } = useAuth(); // Use currentUser from AuthContext


  return (
    <BrowserRouter>
      <div className="app-layout">
        {/* TopBar stays persistent */}
        <Routes>
          <Route
            path="/dashboard"
            element={currentUser ? <Dashboard /> : <Navigate to="/login" />}
          />
          <Route
            path="/profile"
            element={currentUser ? <Profile /> : <Navigate to="/login" />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
