import React from "react";
import Chart from "./Chart";
import "../client.css";

function ActivityCharts({ user, moodData, sleepData }) {
  return (
    <>
      <h3>Activity</h3>
      <div className="activity-charts">
        <Chart
          title="Mood"
          dataPoints={moodData.map((point) => ({
            ...point,
            score: Number(point.score), // Ensure score is a number
          }))}
        />
        <Chart
          title="Sleep"
          dataPoints={sleepData.map((point) => ({
            ...point,
            score: Number(point.score), // Ensure score is a number
          }))}
        />
      </div>
      
    </>
  );
}

export default ActivityCharts;
