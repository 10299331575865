import React, { useState, useEffect } from 'react';
import { useUser } from '../context/UserContext'; // Import UserContext to access PendingUsers
import Popup from './Popup';
import './ApproveUser.css'; // Updated styles
import { FaUserCircle } from 'react-icons/fa'; // Person icon for default profile picture

function InviteUserPopup({ trigger, setTrigger }) {
  const { userData } = useUser(); // Get the userData, assuming PendingUsers exists here
  const [requests, setRequests] = useState([]); // Use PendingUsers from userData
  const [error, setError] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/data/pending/${userData.userId}`); // Replace with the actual clientId
        const result = await response.json();
      

        setRequests(result); // Assuming the API response is already structured as groups
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to fetch data");
      }
    };

    fetchData();
  }, []);

  const handleAccept = async (index) => {
    try { 
      const response = await fetch(`${API_BASE_URL}/api/user/approve/${userData.userId}/${requests[index].userId}/Individuals`);
    
      alert(`Accepted ${requests[index].name}`); 
  } 
    catch (err) {
      console.error("Error Pushing User");
    }
    
  };

  const handleDecline = async (index) => {
    try { 
      const response = await fetch(`${API_BASE_URL}/api/user/decline/${userData.userId}/${requests[index].userId}/Individuals`);
   
      alert(`Accepted ${requests[index].name}`); 
  } 
    catch (err) {
      console.error("Error Pushing User");
    }
    alert(`Declined ${requests[index].name}`);
  };

  return (
    <Popup trigger={trigger} setTrigger={setTrigger}>
      <div className="invite-popup">
        <h3 className="popup-header">Requests</h3>
        <ul className="requests-list">
          {requests.length > 0 ? (
            requests.map((request, index) => (
              <li key={index} className="request-item">
                <div className="request-info">
                  {/* Show profile picture if available, otherwise show default icon */}
                  {request.profilePictureUrl ? (
                    <img
                      src={request.profilePictureUrl}
                      alt="Profile"
                      className="profile-image"
                    />
                  ) : (
                    <FaUserCircle className="profile-placeholder" />
                  )}
                  <div>
                    <p className="request-name">{request.name}</p>
                    <p className="request-time">{request.time}</p>
                  </div>
                </div>
                <div className="request-actions">
                  <button
                    className="action-button accept"
                    onClick={() => handleAccept(index)}
                  >
                    Accept
                  </button>
                  <button
                    className="action-button decline"
                    onClick={() => handleDecline(index)}
                  >
                    Decline
                  </button>
                </div>
              </li>
            ))
          ) : (
            <p>No pending requests</p>
          )}
        </ul>
      </div>
    </Popup>
  );
}

export default InviteUserPopup;
