import React from "react";
import ReplyPostTile from "./ReplyPostTile";
import "../client.css";

function ActivityFeed({ user, replyPosts }) {
  return (
    <>
    <h3>Replies</h3>
    <div className="activity-feed">
      {replyPosts.map((post, index) => (
        <ReplyPostTile
          key={index}
          prompt={post.prompt}
          response={post.response}
          date={post.createdAt}
        />
      ))}
    </div>
    </>
  );
}

export default ActivityFeed;
