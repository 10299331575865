import React, { createContext, useContext, useState, useEffect } from "react";
import { onAuthStateChanged, signOut, sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase"; // Import the auth instance from firebase.js
import Cookies from 'js-cookie'; // To manage cookies on client-side

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const login = (userData) => {
    setCurrentUser(userData);
    Cookies.set("sessionToken", userData.token, { expires: 14 }); // store session token
  };

  const logout = async () => {
    
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/logout`, {
      method: 'POST',
      credentials: 'include',
    });
   
    setCurrentUser(null);
    Cookies.remove("sessionToken"); // remove session token
    Cookies.remove("session");
    await signOut(auth);
    return response;
  };

  const resetPassword = (email) => {
    return sendPasswordResetEmail(auth, email)
      .then(() => {
        console.log('Password reset email sent successfully.');
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
        throw error;
      });
  };

  const checkAuth = async () => {
    const sessionToken = Cookies.get("sessionToken");
    if (sessionToken) {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/protected`, {
        method: 'GET',
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setCurrentUser(data.user);
      } else {
        setCurrentUser(null);
        Cookies.remove("sessionToken");
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    // Call checkAuth to check session token
    checkAuth();

    // Use Firebase's onAuthStateChanged as a fallback
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setCurrentUser({ ...user, token });
          Cookies.set("sessionToken", token, { expires: 14 });
        });
      } else {
        setCurrentUser(null);
        Cookies.remove("sessionToken");
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, login, logout, loading, resetPassword }}>
      {loading ? <p>Loading...</p> : children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
