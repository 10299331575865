import React, { useState } from "react";

function GroupList({ groupName, users, onSelectUser }) {
  const [activeUserId, setActiveUserId] = useState(null);

  const handleUserClick = (user) => {
    setActiveUserId(user.userId); // Set the clicked user as active
    onSelectUser(user); // Call the parent function
  };

  return (
    <div className="group-list">
      <h3 className="group-title">{groupName}</h3>
      <ul>
        {users.map((user) => (
          <li
            key={user.userId}
            className={`user-item ${activeUserId === user.userId ? "active" : ""}`}
            onClick={() => handleUserClick(user)}
            tabIndex="0" // Makes the list items focusable via keyboard
          >
            {user.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default GroupList;
