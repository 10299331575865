// UserContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useAuth } from "./AuthContext"; // Import AuthContext

const UserContext = createContext();

// UserProvider component
export const UserProvider = ({ children }) => {
  const { currentUser } = useAuth(); // Get currentUser from AuthContext
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        const db = getFirestore();
        const userDocRef = doc(db, "Clients", currentUser.uid); // Ensure uid is used to fetch user data
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
            const userData = {...userDocSnap.data(),userId: userDocSnap.id};
            setUserData(userData);
        } else {
          console.log("No such document!");
        }
        
      } else {
        setLoading(false); // Set loading false even if no current user
      }
    };

    if (currentUser) {
      fetchUserData();
      console.log(userData);
      setLoading(false);
    } else {
      setLoading(false); // No currentUser, so we stop loading
    }
  }, [currentUser]);

  if (loading) {
    return <div>Loading user data...</div>; // Loading state
  }

  
  return (
    <UserContext.Provider value={{ userData }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use UserContext
export const useUser = () => useContext(UserContext);
