// PostTile.jsx
import React from 'react';
import "./styles.css";

const PostTile = ({ heading, text, time }) => (
  <div className="post-tile">
    <h3>{heading}</h3>
    <p>{text}</p>
  </div>
);

export default PostTile;
