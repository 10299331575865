import React, { useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import ActivityCharts from "./components/ActivityCharts";
import ActivityFeed from "./components/ActivityFeed";
import "./client.css";
import Spacer from "./components/Spacer";
import Username from "./username";
import { useUser } from "./context/UserContext";

function Client() {
  const [selectedUser, setSelectedUser] = useState(null);
  const [replyPosts, setReplyPosts] = useState([]);
  const [activityData, setActivityData] = useState({ mood: [], sleep: [] });
  const { userData } = useUser();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    if (selectedUser) {
      // Fetch data when a user is selected
      fetchUserActivity(selectedUser.userId);
    }
  }, [selectedUser]);

  // Function to fetch data from the Express API
  const fetchUserActivity = async (userId) => {
    try {
      // Fetch ReplyPosts (prompts and responses)
      const replyPostResponse = await fetch(
        `${API_BASE_URL}/reply-posts/${userId}`
      );
      const replyPostData = await replyPostResponse.json();
      setReplyPosts(replyPostData.replyPosts || []);

      // Fetch activity data (mood and sleep)
      const activityResponse = await fetch(
        `${API_BASE_URL}/activity/${userId}`
      );
      const activityData = await activityResponse.json();
      setActivityData(activityData);
    } catch (error) {
      console.error("Error fetching user activity:", error);
    }
  };

  return (
    <div className="app-container">
      <Sidebar onSelectUser={setSelectedUser} />
      {selectedUser && (
        <div className="main-content">
          <div className="username-sticky">
            <Username
              name={selectedUser.name}
              profilePicture={selectedUser.profilePicture}
            />
          </div>
          <div className="scrollable-content">
            <ActivityCharts
              user={selectedUser.name}
              moodData={activityData.mood}
              sleepData={activityData.sleep}
            />
            <Spacer />
            {replyPosts.length > 0 ? (
              <ActivityFeed user={selectedUser.name} replyPosts={replyPosts} />
            ) : (
              <div>No replies found</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Client;
