import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from './img/icon.svg';
import "./Login.css";
import { useAuth } from "./context/AuthContext";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { login } = useAuth();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    // Automatically check if the user is already authenticated
    const checkAuth = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/protected`, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          await login(data.user);  // log in user if session exists
          navigate('/dashboard');
        }
      } catch (err) {
        console.error('Authentication check failed:', err);
      }
    };
    checkAuth();
  }, [login, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      setError("Password must be at least 6 characters long.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email: username, password }),
      });

      const data = await response.json();
      if (response.ok) {
        await login(data.user); // log in the user after successful authentication
        navigate("/dashboard");
      } else {
        setError(data.error || "Failed to log in. Please check your credentials.");
      }
    } catch (err) {
      setError("Failed to log in. Please check your credentials.");
      console.error('Login error:', err);
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={logo} alt="Marble Connect Logo" className="logo" />
        <h2>Welcome to Marble Connect</h2>
        <p>Your AI-guided personal space</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Username</label>
            <input
              type="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="login-btn">Sign In</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
