// Username.js
import { useUser } from "./context/UserContext";
import "./username.css";

export const Username = ({name, profilePicture}) => {// Assuming useUser returns an object with 'name' and 'profilePicture' properties

    return (
        <div className="username-container">
            <img id="pfp-username" src={profilePicture} alt="Profile" />
            <span id="name">{name}</span>
        </div>
    );
};

export default Username;
