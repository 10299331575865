import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './profile.css'; // Import the CSS file
import { useUser } from './context/UserContext';
import { useAuth } from './context/AuthContext';

function ProfilePage() {
  // State variables for user details
  const navigate = useNavigate();
  const { userData } = useUser();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const { logout, resetPassword } = useAuth(); // Assume resetPassword is a method in your AuthContext

  const handleLogout = async () => {
    try {
      const response = await logout();

      if (response.ok) {
        // Successfully logged out
        navigate('/login'); // Redirect to login page
      } else {
        // Handle errors
        console.error('Logout failed', response);
      }
    } catch (err) {
      console.error('Logout error:', err);
    }
  };

  const handlePassword = async () => {
    try {
      // Initiate password reset process
      await resetPassword(userData.email); // Using user email to trigger password reset
      alert('Password reset link has been sent to your email.');
    } catch (err) {
      console.error('Password reset error:', err);
      alert('Failed to initiate password reset. Please try again.');
    }
  };

  return (
    <div className="container">
      <h1 className="heading">
        <span className="icon">👤</span> My Profile
      </h1>
      
      <div className="profile-section">
        <div className="input-group">
          <label>Username</label>
          <input className="input" value={userData.username} readOnly />
        </div>

        <div className="input-group">
          <label>Name</label>
          <input className="input" value={userData.name} readOnly />
        </div>

        <div className="input-group">
          <label>Email</label>
          <input className="input" value={userData.email} readOnly />
        </div>
      </div>

      <div className="button-container">
        <button onClick={handlePassword} className="button">Change Password</button>
        <button onClick={handleLogout} className="button">Log Out</button>
      </div>
    </div>
  );
}

export default ProfilePage;
