import React, { useState } from "react";
import "./ReplyPostTile.css";

const ReplyPostTile = ({ prompt, response, date }) => {
  const [showResponse, setShowResponse] = useState(false);

  const toggleResponse = () => {
    setShowResponse(!showResponse);
  };

  return (
    <div className="reply-post-tile" onClick={toggleResponse}>
      <div className="reply-post-header">
        <h4>{prompt}</h4>
        <span className="reply-post-date">{date}</span>
      </div>
      <p>{response}</p>
    </div>
  );
};

export default ReplyPostTile;
