import { useUser } from "../context/UserContext";
import Popup from "./Popup";
import "./certainpopup.css";
import { useState, useEffect } from "react";

function ManageGroup({ trigger, setTrigger, docId }) {
  const [activeTab, setActiveTab] = useState("deleteGroup");
  const [selectedGroupToDelete, setSelectedGroupToDelete] = useState("");
  const [selectedGroupToMoveFrom, setSelectedGroupToMoveFrom] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedGroupToMoveTo, setSelectedGroupToMoveTo] = useState("");
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState({});
  const { userData } = useUser();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

  useEffect(() => {
    // Fetch the groups and users when the component mounts
    fetchGroupsAndUsers();
  }, []);

  // Fetch groups and users from the backend
  const fetchGroupsAndUsers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/get-groups-and-users/${userData.userId}`);
      const data = await response.json();
      

      // Assuming data.users is in the format { groupName: [userIds] }
      const userInfo = {};

      for (const [groupName, userIds] of Object.entries(data.users)) {
        userInfo[groupName] = []; // Initialize the group in the result

        // Iterate over each userId in the group
        for (const userId of userIds) {
          // Fetch user info from the backend using a separate API
          const userResponse = await fetch(`${API_BASE_URL}/get-user-info/${userId}`);
          const userData = await userResponse.json();

          if (userData) {
            // Add user data (name and userId) to the respective group
            userInfo[groupName].push({
              userId: userId,
              name: userData.name || "Unknown", // Default to 'Unknown' if no name
            });
          }
        }
      }

      setGroups(data.groups); // Set the groups as fetched
      setUsers(userInfo); // Set the detailed user info

    } catch (error) {
      console.error("Error fetching groups and users:", error);
    }
  };

  useEffect(() => {
    if (selectedGroupToMoveFrom) {
      // Fetch users from the selected group and update available users
      setAvailableUsers(users[selectedGroupToMoveFrom] || []);
    }
  }, [selectedGroupToMoveFrom, users]);

  // Delete a group
  const handleDeleteGroup = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/delete-group`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ docId: userData.userId, groupName: selectedGroupToDelete }),
      });

      if (response.ok) {
        fetchGroupsAndUsers(); // Refresh groups and users
        alert(`Group ${selectedGroupToDelete} deleted successfully.`);
      } else {
        throw new Error("Failed to delete group");
      }
    } catch (error) {
      console.error("Error deleting group:", error);
      alert("Error deleting group.");
    }
  };

  // Move users between groups
  const handleMoveUsers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/move-users`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          docId: userData.userId,
          fromGroup: selectedGroupToMoveFrom,
          toGroup: selectedGroupToMoveTo,
          userIds: selectedUsers, // Send user IDs for the move
        }),
      });

      if (response.ok) {
        fetchGroupsAndUsers(); // Refresh groups and users
        alert("Users moved successfully.");
      } else {
        throw new Error("Failed to move users");
      }
    } catch (error) {
      console.error("Error moving users:", error);
      alert("Error moving users.");
    }
  };

  // Handle the accept action and close the popup
  const handleAccept = () => {
    if (activeTab === "deleteGroup") {
      handleDeleteGroup();
    } else if (activeTab === "moveUsers") {
      handleMoveUsers();
    }
    setTrigger(false); // Close the popup
  };

  return (
    <Popup trigger={trigger} setTrigger={setTrigger}>
      <div className="group-popup-header">
        <h3>Manage Group</h3>
        <div className="tabs">
          <button
            className={activeTab === "deleteGroup" ? "active-tab" : ""}
            onClick={() => setActiveTab("deleteGroup")}
          >
            Delete Group
          </button>
          <button
            className={activeTab === "moveUsers" ? "active-tab" : ""}
            onClick={() => setActiveTab("moveUsers")}
          >
            Move Users
          </button>
        </div>
      </div>

      {activeTab === "deleteGroup" && (
        <div className="delete-group-tab">
          <h4>Select Group to Delete</h4>
          <div className="input-container">
            <select
              value={selectedGroupToDelete}
              onChange={(e) => setSelectedGroupToDelete(e.target.value)}
              className="full-width-input"
            >
              <option value="">Select Group</option>
              {groups.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      {activeTab === "moveUsers" && (
        <div className="move-users-tab">
          <h4>Select Group to Move From</h4>
          <div className="input-container">
            <select
              value={selectedGroupToMoveFrom}
              onChange={(e) => setSelectedGroupToMoveFrom(e.target.value)}
              className="full-width-input"
            >
              <option value="">Select Group</option>
              {groups.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </select>
          </div>

          <h4>Select Users to Move</h4>
          <div className="input-container">
            <select
              multiple
              value={selectedUsers}
              onChange={(e) =>
                setSelectedUsers(
                  Array.from(e.target.selectedOptions, (option) => option.value)
                )
              }
              className="full-width-input"
            >
              {availableUsers.map((user) => (
                <option key={user.userId} value={user.userId}>
                  {user.name} {/* Display user name */}
                </option>
              ))}
            </select>
          </div>

          <h4>Select Group to Move To</h4>
          <div className="input-container">
            <select
              value={selectedGroupToMoveTo}
              onChange={(e) => setSelectedGroupToMoveTo(e.target.value)}
              className="full-width-input"
            >
              <option value="">Select Group</option>
              {groups
                .filter((group) => group !== selectedGroupToMoveFrom) // Exclude the group selected to move from
                .map((group) => (
                  <option key={group} value={group}>
                    {group}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}

      {/* Accept button */}
      <div className="button-container">
        <button className="accept-button" onClick={handleAccept}>
          Accept
        </button>
      </div>
    </Popup>
  );
}

export default ManageGroup;
